@import "../../shared-frontend/src/utils/fonts/font.scss";
@import "../../shared-frontend/src/utils/fonts/design_fonts.scss";
@import "../../shared-frontend/src/index.scss";
@import "./utils/styles/bootstrap.scss";
@import "~react-toastify/dist/ReactToastify.css";
@import "~swiper/swiper-bundle.css";

@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");

html,
body {
  width: 100%;
  min-height: 100%;
}

body {
  margin: 0;
  font-family: "Marcellus", "Lato", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", "Arial", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  &,
  &:hover {
    color: inherit;
    text-decoration: none;
  }
  &:focus {
    outline: none;
  }
}

button:focus {
  outline: none;
}

// react-toastify packages styles
.Toastify__toast-body {
  font-size: 1rem;
}

:root {
  --toastify-color-success: #afbdac;
  --toastify-text-color-success: #404040;
  --toastify-color-progress-success: #404040;
}

.Toastify__toast--success {
  .Toastify__close-button {
    color: var(--toastify-color-progress-success);
  }
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}

// FabricJs custom css
// https://github.com/fabricjs/fabric.js/issues/2904
textarea[data-fabric-hiddentextarea] {
  top: 0;
}

// Swiper
.swiper-pagination {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  margin: 6px !important;
  transition: all 0.5s ease 0s;
  border: 1px solid transparent;
  background-color: #1f1f1f;
}

.swiper-pagination .swiper-pagination-bullet-active {
  width: 18px;
  height: 18px;
  border-color: #333333;
  background-color: transparent;
}
